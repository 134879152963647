import styles from './styles.module.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { MainImage } from 'src/components/mainImage';
import {
	ContentfulMediaPage,
	ContentfulArticleResource,
	ContentfulVideoResource,
	ContentfulReportResource,
	ContentfulPublicationResource,
	ContentfulProjectResource,
} from 'src/graphql-types';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	SectionWithSearchResourceTemplate,
	mapResources,
} from 'src/templates/sectionWithSearchResourceTemplate/sectionWithSearchTemplate';
import {
	defaultMediaPageSections,
	FilterContentTypeItems,
	RelevantPages,
	getHrImageUrl,
} from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import { curatedTitle } from 'src/utils/curated-title';
import { SectionContainer } from 'src/components/sectionContainer';

interface PageContext {
	pageContext: {
		mediaPage: ContentfulMediaPage;
		articlesSectionArticles: {
			nodes: ContentfulArticleResource[];
		};
		mediaSectionArticles: {
			nodes: ContentfulArticleResource[];
		};
		mediaSectionVideos: {
			nodes: ContentfulVideoResource[];
		};
		mediaSectionReports: {
			nodes: ContentfulReportResource[];
		};
		mediaSectionPublications: {
			nodes: ContentfulPublicationResource[];
		};
		mediaSectionProjects: {
			nodes: ContentfulProjectResource[];
		};
	};
}

export const MediaPageDetails = (props: PageContext) => {
	const { mediaPage: pageData } = props?.pageContext;
	const {
		articlesSectionArticles,
		mediaSectionArticles,
		mediaSectionVideos,
		mediaSectionReports,
		mediaSectionPublications,
		mediaSectionProjects,
	} = props.pageContext;

	if (!pageData) {
		console.error('No page data for this MediaPage');
		return (
			<SectionContainer withBorderTop>
				<p className={styles.message}>This page is under maintenance. Please try again later</p>
			</SectionContainer>
		);
	}

	const {
		contentful_id,
		title,
		subtitle,
		titleBackgroundImage,
		featuredArticles,
		featuredMediaReleases,
		articlesHeading,
		mediaReleasesHeading,
	} = pageData;

	return (
		<DefaultLayoutTemplate>
			<Helmet>
				<title>{curatedTitle(title)}</title>
			</Helmet>

			<MainImage
				backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
				header={title}
				paragraph={subtitle}
			/>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(featuredArticles)}
				publicRelevantResources={mapResources(articlesSectionArticles?.nodes)}
				page={RelevantPages.MediaArticles}
				resourceTypes={[FilterContentTypeItems.Article]}
				title={articlesHeading || defaultMediaPageSections.articles}
				titleId={LinkHelper.parseInternalLink(articlesHeading || defaultMediaPageSections.articles)}
			/>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(featuredMediaReleases)}
				publicRelevantResources={mapResources([
					...(mediaSectionArticles?.nodes || []),
					...(mediaSectionPublications?.nodes || []),
					...(mediaSectionVideos?.nodes || []),
					...(mediaSectionReports?.nodes || []),
					...(mediaSectionProjects?.nodes || []),
				])}
				page={RelevantPages.MediaReleases}
				resourceTypes={[
					FilterContentTypeItems.Article,
					FilterContentTypeItems.Publication,
					FilterContentTypeItems.Video,
					FilterContentTypeItems.Report,
					FilterContentTypeItems.Project,
				]}
				title={mediaReleasesHeading || defaultMediaPageSections.media}
				titleId={LinkHelper.parseInternalLink(
					mediaReleasesHeading || defaultMediaPageSections.media
				)}
			/>
		</DefaultLayoutTemplate>
	);
};

export default class MediaPage extends React.Component<PageContext> {
	render() {
		return <MediaPageDetails {...this.props} />;
	}
}
