import styles from './styles.module.css';
import classNames from 'classnames';
import React, { useState } from 'react';
import { CustomInput } from 'src/components/formComponents/customInput';
import { SearchIcon } from 'src/icons/search';

interface InputProps {
	onHowCanWeHelp?: (searchTerm: string) => any;
}

const HowCanIHelpInput = (props: InputProps) => {
	const [searchTerm, setSearchTerm] = useState('');

	const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === 13) {
			props.onHowCanWeHelp && props.onHowCanWeHelp(searchTerm);
		}
	};

	return (
		<div className={styles.inputContainer}>
			<span className={styles.inputIcon}>
				<SearchIcon color="#52D07A" />
			</span>
			<input
				type="text"
				className={styles.input}
				placeholder="How can we help you?"
				onKeyUp={onKeyUp}
				onChange={(event) => setSearchTerm(event.target.value)}
			/>
		</div>
	);
};

interface Props {
	backgroundImageSrc?: string | null;
	type?: 'home' | 'default';
	header?: string | null;
	paragraph?: string | null;
	onHowCanWeHelp?: (searchTerm: string) => any;
	imageContainerClassName?: string;
	gradientClassName?: string;
}

export const MainImage = (props: Props) => {
	const {
		backgroundImageSrc,
		header,
		paragraph,
		onHowCanWeHelp,
		type = 'default',
		imageContainerClassName,
		gradientClassName,
	} = props;

	const wrapperClass = classNames([
		{ [styles.wrapperDefault]: type === 'default' },
		{ [styles.wrapperHome]: type === 'home' },
	]);

	const imageContainerClass = classNames([
		{ [styles.imageContainerDefault]: type === 'default' },
		{ [styles.imageContainerHome]: type === 'home' },
		imageContainerClassName,
	]);

	const outerContentClass = classNames([
		{ [styles.outerContentDefault]: type === 'default' },
		{ [styles.outerContentHome]: type === 'home' },
	]);

	const innerContentClass = classNames([
		{ [styles.innerContentDefault]: type === 'default' },
		{ [styles.innerContentHome]: type === 'home' },
	]);

	const gradientClass = classNames([styles.gradient, gradientClassName]);

	return (
		<div className={wrapperClass}>
			{backgroundImageSrc && (
				<div
					className={imageContainerClass}
					style={{ backgroundImage: `url(${backgroundImageSrc})` }}
				></div>
			)}
			<div className={gradientClass}></div>
			<div className={outerContentClass}>
				<div className={innerContentClass}>
					<h1 className={styles.header}>{header}</h1>
					<h5 className={styles.mainImageParagraph}>{paragraph}</h5>
					{onHowCanWeHelp && <HowCanIHelpInput onHowCanWeHelp={onHowCanWeHelp} />}
				</div>
			</div>
		</div>
	);
};
