import styles from './styles.module.css';
import React, { ReactNode, useEffect, useState, useMemo } from 'react';

import { ReadMore } from 'src/components/readMore';
import * as Icons from 'src/icons';
import { RelevantPages, SupportedSearchContentTypes, formatDate } from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import {
	ResultResponseItem,
	ResultStatus,
	useSearchManager,
	UseSearchManagerResult,
} from 'src/utils/search-manager';
import {
	SectionContainerGrid,
	SectionContainer,
	SectionContainerTitle,
} from 'src/components/sectionContainer';
import {
	Maybe,
	AllResourcesGroup,
	ContentfulPublicationResource,
	ContentfulProjectResource,
} from 'src/graphql-types';
import { SortByItemType } from 'src/components/formComponents/search/searchResults';
import { useLoginManager } from 'src/utils/login-manager';

type ContentfulResource = Maybe<AllResourcesGroup>;

const getResourceType = (resource: any) => {
	return (
		resource.articleType ||
		resource.projectType ||
		resource.publicationType ||
		resource.reportType ||
		undefined
	);
};

const sortResource = (
	resource1: ContentfulResource | ResultResponseItem,
	resource2: ContentfulResource | ResultResponseItem
) => {
	const r1SortField =
		(resource1 as any)?.publicationDate ||
		(resource1 as ContentfulProjectResource)?.startDate ||
		(resource1 as ContentfulResource)?.updatedAt ||
		'';
	const r2SortField =
		(resource2 as any)?.publicationDate ||
		(resource2 as ContentfulProjectResource)?.startDate ||
		(resource2 as ContentfulResource)?.updatedAt ||
		'';
	// sorts in reverse: most recent to oldest.
	// date is expected to be YYYY-MM-DD, so string sort is fine
	return r1SortField < r2SortField ? 1 : r1SortField > r2SortField ? -1 : 0;
};

export const mapResources = (
	contentFulResources?: ContentfulResource[] | null
): ResultResponseItem[] => {
	return (
		(contentFulResources || [])
			// removes duplicates
			.reduce<ContentfulResource[]>(
				(unique, item) => (item && unique.includes(item) ? unique : [...unique, item]),
				[]
			)
			// removes undefined items
			.filter((resource) => resource)
			// maps items of type ContentfulResource to ResultResponseItem
			.map((resource) => ({
				contentful_id: resource!.contentful_id || undefined,
				type: (resource!.internal?.type || undefined) as SupportedSearchContentTypes,
				resourceType: getResourceType(resource),
				publicationDate: (resource as ContentfulPublicationResource)!.publicationDate,
				thumbnailLink: {
					title: resource!.thumbnailLink?.title || '',
					description: resource!.thumbnailLink?.description || undefined,
					imageSrc: resource!.thumbnailLink?.thumbnailImage?.fluid?.src,
				},
				externalUrl:
					(resource as any)!.externalArticleUrl || (resource as any)!.externalPublicationUrl,
				videoUrl: (resource as any)!.videoFile?.file?.url || (resource as any)!.externalVideoUrl,
				languages: (resource as any)!.otherLanguageAssets
					? (resource as any)!.otherLanguageAssets
							.filter((l: any) => l)
							.map((lAsset: { language: string }) => lAsset.language)
					: undefined,
			}))
	);
};

interface Props {
	/** public featured resources (private-ones are ignored) */
	featuredResources?: ResultResponseItem[];
	// private relevant resources are fetched inside component, so we don't pass it in props.
	/** public relevant resources */
	publicRelevantResources?: ResultResponseItem[];
	/** page for which the resources are relevant to */
	page: RelevantPages;
	/** Resource Types of this type(s) will be loaded and searched for, but the featured resources have precedence. */
	resourceTypes?: (
		| 'Research Project'
		| 'Research Report'
		| 'Publication'
		| 'Article'
		| 'Corporate'
		| 'Magazine'
		| 'Video'
		| 'Other'
	)[];
	/** title of the container containing the collection of resources */
	title: string;
	/** for page internal link /pageExample#internal-link-example */
	titleId?: string;
	containerIsGrey?: boolean;
	/** if resources have thumbnail image, show it. */
	showImage?: boolean;
}

const getUniqueResources = (resources: ResultResponseItem[]) => {
	const uniqueIdsHelper = new Set<string>();
	let uniqueResources: ResultResponseItem[] = [];
	resources.forEach((resource) => {
		if (resource && resource.contentful_id) {
			if (!uniqueIdsHelper.has(resource.contentful_id)) {
				uniqueResources.push(resource);
				uniqueIdsHelper.add(resource.contentful_id);
			}
		}
	});
	return uniqueResources;
};

interface SetFilterCollectionParams {
	useSearch: UseSearchManagerResult;
	page?: RelevantPages;
	resourceTypes?: string[];
	triggerSearch: boolean;
}
const setFilterCollection = ({
	useSearch,
	page,
	resourceTypes,
	triggerSearch,
}: SetFilterCollectionParams) => {
	if (page || resourceTypes) {
		useSearch.setFilterCollection(
			{
				page: page ? new Set<RelevantPages>([page]) : undefined,
				contentTypes: resourceTypes ? new Set(resourceTypes) : undefined,
				sort: new Set<SortByItemType>().add('dateDesc'),
			},
			triggerSearch
		);
	}
};

// this constant differs from the one in the search page.
// the search page can show many items per page, this is a section of a page so it should show less items per page to avoid polluting the parent page with too many items.
const DEFAULT_ITEMS_PER_PAGE = 4;

export const SectionWithSearchResourceTemplate = (props: Props) => {
	const {
		featuredResources,
		publicRelevantResources,
		page,
		resourceTypes,
		title,
		containerIsGrey,
		showImage,
	} = props;
	const useSearch = useSearchManager(DEFAULT_ITEMS_PER_PAGE);
	const loginManager = useLoginManager();
	const uniquePropsResources = useMemo(
		() =>
			getUniqueResources([
				// don't sort featured resources, show as they are ordered by staff in Contentful
				...(featuredResources || []),
				...(publicRelevantResources || []).sort(sortResource),
			]),
		[featuredResources, publicRelevantResources]
	);
	const [resources, setResources] = useState<ResultResponseItem[]>([]);
	// are resources from search or just from props (featured+publicRelevant).
	const [areResourcesFromSearch, setAreResourcesFromSearch] = useState(false);
	// we need to have our own pagination, since the initial render may have many items even before we use the useSearchManager which has its own pagination.
	const [currentPage, setCurrentPage] = useState(1);

	// do this on first render (or when props change)
	useEffect(() => {
		setResources(uniquePropsResources);
		setAreResourcesFromSearch(false);
		setFilterCollection({ useSearch, page, resourceTypes, triggerSearch: false });
	}, [props]);

	// do this when search result changes or when user login status changes
	useEffect(() => {
		// if we have results from a search show them.
		if (useSearch.result.status === ResultStatus.success) {
			const searchResultResources = (useSearch.result.data?.items || []).sort(sortResource);
			const isSearchboxEmpty = !useSearch.query;
			// featured resources are shown when there is no search query
			// for example, when users clear the searchbox.
			// or when we trigger a empty-query search just so we can get all relevant resources (private and public).
			const featuredResourcesIfNeeded = isSearchboxEmpty ? featuredResources : undefined;
			setResources(
				getUniqueResources([...(featuredResourcesIfNeeded || []), ...searchResultResources])
			);
			setAreResourcesFromSearch(true);
			// else if we haven't searched anything, or we called useSearch.clearQuery()
		} else if (useSearch.result.status === ResultStatus.nothingSearched) {
			if (loginManager.isLoggedIn) {
				// lets trigger a search with no query.
				// this gets all relevant resources (private and public)
				// This in turn changes the useSearch.result, so this useEffect we are currently in gets called again, only this time with ResultStatus.success
				setFilterCollection({ useSearch, page, resourceTypes, triggerSearch: true });
				setAreResourcesFromSearch(true);
			} else {
				// show resources from props (featured + relevant resources that are public)
				setResources(uniquePropsResources);
				setAreResourcesFromSearch(false);
			}
		}
	}, [useSearch.result, loginManager.isLoggedIn]);

	const getOnLoadMore = () => {
		// are we showing resources from search result? does the search result have more items to fetch?
		// then let the search engine handle pagination.
		if (areResourcesFromSearch && useSearch.result.data?.nextItemNumber) {
			return useSearch.loadMore;
		}

		// are we showing 'static' resources (Gatsby)? do we have more of those to show?
		// then we handle pagination here.
		if (!areResourcesFromSearch && resources.length > currentPage * DEFAULT_ITEMS_PER_PAGE) {
			return () => setCurrentPage((prevPage) => prevPage + 1);
		}

		// no more resources to show, we are showing all the resources already.
		return undefined;
	};

	const getResultsJsx = () => {
		const getContainerJsx = (children: ReactNode) => {
			return <div className={styles.rearchResult}>{children}</div>;
		};

		if (useSearch.result.status === ResultStatus.searching) {
			return getContainerJsx(<p className={styles.message}>Searching...</p>);
		}

		if (resources.length === 0) {
			return getContainerJsx(<p className={styles.message}>There are no Resources to show</p>);
		}

		return getContainerJsx(
			<SectionContainerGrid columns="2">
				{resources
					// filter undefined items
					.filter((r) => r)
					// if search has taken place let search engine handle pagination, otherwise we handle it.
					.slice(0, areResourcesFromSearch ? Infinity : DEFAULT_ITEMS_PER_PAGE * currentPage)
					.map((resource) => {
						const Icon =
							Icons[resource.thumbnailLink?.thumbnailIcon as keyof typeof Icons] || undefined;
						let linkTo: string | undefined = LinkHelper.getLinkOfContentType(
							resource.type,
							resource.contentful_id
						);
						let externalLink: string | undefined = undefined;
						if (resource.externalUrl) {
							linkTo = undefined;
							externalLink = resource.externalUrl;
						}
						return (
							<ReadMore
								key={resource.contentful_id}
								image={
									showImage
										? {
												src: resource.thumbnailLink?.imageSrc,
												style: { height: '300px', width: 'auto' },
										  }
										: undefined
								}
								icon={
									showImage
										? {
												Icon,
												style: { height: '300px', width: 'auto' },
										  }
										: undefined
								}
								title={{
									titleText: resource.thumbnailLink?.title,
								}}
								publishedDate={formatDate(resource.publicationDate)}
								linkSection={{
									linkTo,
									externalLink,
									withBorderTop: true,
									readMoreText: resource.videoUrl ? 'Watch Video' : undefined,
								}}
								asVideoUrl={resource.videoUrl ? linkTo || externalLink : undefined}
								asCard
							>
								{resource.thumbnailLink?.description}
							</ReadMore>
						);
					})}
			</SectionContainerGrid>
		);
	};

	return (
		<SectionContainer
			isGrey={containerIsGrey}
			onLoadMore={getOnLoadMore()}
			className={styles.searchResult}
		>
			<SectionContainerTitle
				text={title}
				id={props.titleId}
				onSearch={(searchTerm) =>
					searchTerm?.length ? useSearch.queryFor(searchTerm) : useSearch.clearQuery()
				}
				withSeparator
			/>
			{getResultsJsx()}
		</SectionContainer>
	);
};
